<template>
  <div>
    <ViewTemplateWithTable :panel="panel" :templateList="templateList">
      <div slot="content-buttons">
        <Button _key="btnCollaboratorCreate" title="Colaborador" classIcon="fas fa-plus-circle" type="primary"
          size="small" eventName="collaboratorCreate" :clicked="create" />
      </div>
    </ViewTemplateWithTable>
  </div>
</template>

<script>
import ViewTemplateWithTable from "@nixweb/nixloc-ui/src/component/template/ViewTemplateWithTable.vue";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "CollaboratorListView",
  components: {
    ViewTemplateWithTable,
    Button,
  },
  data() {
    return {
      panel: {
        module: "Recursos Humanos",
        title: "Colaborador",
        showFilter: true,
        showSearch: true,
        showButtons: true,
      },
      templateList: {
        urlGetApi: "/api/v1/human-resources/collaborator/get-all",
        urlDeleteAllApi: "/api/v1/human-resources/collaborator/delete",
        showChecks: true,
        headerTable: [
          {
            field: "photo",
            container: "nixloc-photo-collaborator",
            type: "image",
            classCssBody: "img-user",
          },
          {
            field: "companyName",
            title: "Nome",
            type: "link",
            routeName: "collaboratorUpdate",
            iconSearch: true,
            classCssBody: "center-vertical",
          },
          {
            field: "tradeName",
            title: "Apelido",
            type: "text",
            iconSearch: true,
            classCssBody: "center-vertical",
          },
          {
            field: "userAttached",
            title: "Usuário Vinculado",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center center-vertical",
          },
          {
            field: "typeName",
            title: "Tipo",
            type: "text",
            classCssTitle: "text-center",
            classCssBody: "text-center center-vertical",
          },
          {
            field: "statusName",
            title: "Status",
            type: "class",
            fieldComparison: "statusName",
            classCssTitle: "text-center",
            classCssBody: [
              {
                classCss: "text-center center-vertical badge badge-info",
                fieldComparison: "Ativo",
              },
              {
                classCss: "text-center center-vertical badge badge-danger",
                fieldComparison: "Inativo",
              },
            ],
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapMutations("generic", ["removeLoading"]),
    create() {
      this.$router.push({
        name: "collaboratorCreate",
      });
      this.removeLoading(["btnCollaboratorCreate"]);
    },
  },
};
</script>
